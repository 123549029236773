
import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)

let router = new Router()

let routeConfig = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Home',
    meta: {
      title: '清单自习室官网',
      auth: false
    },
    component: () => import('../views/Home')
  },
  {
    path: '/Login',
    meta: {
      title: '登录',
      auth: false
    },
    component: () => import('../views/Login')
  },
  {
    path: '/Web',
    meta: {
      title: 'Web',
      auth: true
    },
    component: () => import('../views/Web'),
    children: [
      {
        path: '/TodoList',
        meta: {
          title: '清单',
          auth: true
        },
        component: () => import('../views/TodoList')
      },
      {
        path: '/Settings',
        meta: {
          title: '设置',
          auth: true
        },
        component: () => import('../views/Settings')
      }
    ]
  },
  {
    path: '/NotFound',
    meta: {
      title: '404',
      auth: false
    },
    component: () => import('../views/NotFound')
  },
  {
    path: '/*',
    redirect: '/NotFound'
  }
]

// 添加动态路由
router.addRoutes(routeConfig)

export { router }

// router.beforeEach((to, from, next) => {
//   if (to.meta.auth === true && !localStorage.getItem('userToken')) {
//     next({ path: '/login' });
//   } else {
//     next();
//   }
// })
